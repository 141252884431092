import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { forkJoin, Subscription } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import {
  ProyectoDTO,
  VwCrrAvanceUfDTO,
} from "src/app/componentes-comunes/componentes-comunes.dto";
import {
  PrdMaNaturalezaJuridicaDTO,
  PrdMaProcesoAdquisicionDTO,
  PrdMaTipoDocumentoDTO,
  PrdMaUsoSueloDTO,
  PredioDTO,
  PrdMaTipoPropietarioDTO,
  PrdPersonaDTO,
  PrdDisponibilidadPredioDTO,
  TrnMaUnidadMedidaDTO,
  PrdMargenPredioDTO,
  PrdMaTipoMargenPredioDTO,
} from "../../predios.dto";
import { PrediosService } from "../../predios.service";
import { ComponentesComunesService } from "src/app/componentes-comunes/componentes-comunes.service";
import { UnidadFuncionalCarreteroService } from "src/app/informacion-general-carretero/alcance-proyecto/unidad-funcional-carretero/unidad-funcional-carretero.service";
import { MessageService } from "primeng/api";
import { TrnSiNo } from "src/app/parametricas/trn-si-no";
import { ParametricasService } from "src/app/parametricas/parametricas.service";
import { EnumTipoDocumento } from "src/app/comun/constantes/constantes-comunes";
import { Table } from "primeng/table";
import { PrincipalComponent } from "src/app/home/principal/principal.component";

const regexMayorACero = "^[1-9][0-9]*$";
const regexMayorACeroDecimal = "[0-9]+(.[0-9][0-9]*$)?";
const regexAlfabetico = "^[a-zA-Z\s ñÑ.,]+";

@Component({
  selector: "app-crear-editar-predio",
  templateUrl: "./crear-editar-predio.component.html",
  styleUrls: ["./crear-editar-predio.component.sass"],
})
export class CrearEditarPredioComponent implements OnInit {
  blockedDocument: boolean;
  reglasCampoAreaTerrenoRequerido: boolean;
  subscription: Subscription;

  idProyecto: number;
  proyectoSeleccionado: ProyectoDTO;

  vwCrrAvanceUfDTOList: VwCrrAvanceUfDTO[];

  unidadesFuncionales: any = [];
  unidadesFuncionalesFiltered: any[];
  subtramos: any[];

  prdMaNaturalezaJuridicaDTOList: PrdMaNaturalezaJuridicaDTO[];
  prdMaProcesoAdquisicionDTOList: PrdMaProcesoAdquisicionDTO[];
  prdMaTipoDocumentoDTOList: PrdMaTipoDocumentoDTO[];
  prdMaUsoSueloDTOList: PrdMaUsoSueloDTO[];
  sinoList: TrnSiNo[];

  minDate = new Date(1577836800000); //Jan 01 2020
  maxDate = new Date(2082758400000); //Jan 01 2036

  prdMaTipoMargenPredioDTOList: PrdMaTipoMargenPredioDTO[];
  tipoMargenPredioSelected: PrdMaTipoMargenPredioDTO;
  prdMargenPredioDTO: PrdMargenPredioDTO;
  visibleDobleSentidoAbscisado: boolean;
  editarMargenPredio: boolean;
  indexMargenPredio: number;
  dialogoEliminarAbscisa: boolean;
  esConsultaMargenPredio: boolean;

  idPredioHistorico: number;
  predio: PredioDTO;

  disponibilidadPredio: PrdDisponibilidadPredioDTO;

  borrador: boolean;

  isDisablePredioReportado: boolean;
  isDisableAreaRemanente: boolean;
  isDisablePredioSobrante: boolean;
  isDisablePredioArchivado: boolean;

  trnMaUnidadMedidaDTOList: TrnMaUnidadMedidaDTO[];

  departamentos: any = [];
  filtroDepartamentos: any[];
  departamentoSeleccionado: any;
  ciudades: any[] = [];
  filtroCiudades: any[];
  ciudadSeleccionada: any;

  prdPropietarioYaRegistrado: PrdPersonaDTO;
  prdPropietarioYaRegistradoList: PrdPersonaDTO[];

  prdPropietarioACrear: PrdPersonaDTO;

  prdMaTipoPropietarioDTOList: PrdMaTipoPropietarioDTO[];
  tipoPropietarioSelected: PrdMaTipoPropietarioDTO;
  porcentajeParticipacion: number;

  dialogoPropietarios: boolean;
  dialogoCrearPropietario: boolean;
  dialogoCrearBorradorGuardarPredio: boolean;
  dialogoCrearBorradorGuardarDisponibilidad: boolean;
  dialogoPublicarPredio: boolean;
  dialogoNotificarAInterventoria: boolean;
  dialogoDevolverAConcesionario: boolean;
  dialogoAbscisado: boolean;

  trnTiposDocumentoList: any[];
  tipoDocumentoSelected: any;

  filtroDtPropietarios: string;

  participacionSumatoria: number;

  formPredio;
  formAgregarPropietarios;
  formCrearPropietario;
  formDisponibilidad;
  formDevolverAConcesionario;
  formCrearAbscisa;

  @ViewChild("dtPropietariosRegistrados", { static: true })
  dataTableComponent: Table;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private ruta: ActivatedRoute,
    private componentesComunesService: ComponentesComunesService,
    private prediosService: PrediosService,
    private unidadFuncionalCarreteroService: UnidadFuncionalCarreteroService,
    private messageService: MessageService,
    private parametricasService: ParametricasService,
    private principalComponent: PrincipalComponent
  ) {
    // Crear formulario Predio
    this.formPredio = formBuilder.group({
      numeroFichaPredial: [
        null,
        [Validators.required, Validators.maxLength(100)],
      ],
      cedulaCatastral: [null, [Validators.required, Validators.maxLength(100)]],
      folioMatricula: [null, [Validators.required]],
      valorAvaluo: [
        null,
        [
          Validators.pattern(regexMayorACeroDecimal),
          Validators.maxLength(23),
          Validators.required,
        ],
      ],
      unidadFuncional: [null, [Validators.required]],
      subtramo: [null],
      naturalezaJuridica: [null, [Validators.required]],
      areaTerrenoRequerido: [
        null,
        [
          Validators.required,
          ,
          Validators.pattern(regexMayorACeroDecimal),
          Validators.maxLength(9),
        ],
      ],
      longitudEfectivaMetros: [
        null,
        [
          Validators.required,
          Validators.pattern(regexMayorACeroDecimal),
          Validators.maxLength(9),
        ],
      ],
      departamento: [null, [Validators.required]],
      municipio: [null, [Validators.required]],
      /* abscisaInicial: [null, [Validators.required, Validators.maxLength(20)]],
      abscisaFinal: [null, [Validators.required, Validators.maxLength(20)]], */
      predioRequerido: [null, [Validators.required]],
      predioAfectado: [null, [Validators.required]],
      predioOfertado: [null, [Validators.required]],
      predioReportadoFormatoNiif: [null, [Validators.required]],
      trimestreAnioReporteNiif: [null,[Validators.maxLength(30),],],
      areaRemanenteNoDesarrollable: [null, [Validators.required]],
      areaRemanente: [null,[Validators.pattern(regexMayorACeroDecimal),Validators.maxLength(9),],],
      predioSobrante: [null, [Validators.required]],
      areaPredioSobrante: [null,[Validators.pattern(regexMayorACeroDecimal),Validators.maxLength(9),],],
      predioArchivadoFiisicoAni: [null, [Validators.required]],
      numeroRadicacionAni: [null,[Validators.pattern(regexMayorACero),Validators.maxLength(14),],],
      fechaRadicacionAni: [null, null],
      usoSuelo: [null, [Validators.required]],
      unidadMedida: [null, [Validators.required]],
    });

    // Crear formulario Agregar Propietarios

    this.formAgregarPropietarios = formBuilder.group({
      tipoPropietario: [null, [Validators.required]],
      porcentajeParticipacion: [
        null,
        [
          Validators.required,
          Validators.max(100),
          Validators.min(0.00000001),
          Validators.maxLength(3),
          Validators.pattern(regexMayorACeroDecimal),
        ],
      ],
      filtroPropietarios: [null],
    });

    // Crear formulario Crear Propietario
    this.formCrearPropietario = formBuilder.group({
      tipoPropietario: [null, [Validators.required]],
      tipoDocumento: [null, [Validators.required]],
      numeroIdentificacion: [
        null,
        [
          Validators.required,
          Validators.maxLength(10),
          Validators.pattern(regexMayorACero),
        ],
      ],
      primerNombre: [null],
      otrosNombres: [null],
      primerApellido: [null],
      segundoApellido: [null],
      razonSocial: [null],
    });

    // Crear formulario Disponibilidad
    this.formDisponibilidad = formBuilder.group({
      predioDisponible: [null, [Validators.required]],
      fechaDisponibilidad: [null],
      predioAdquirido: [null],
      fechaAdquirido: [null],
      procesoAdquisicion: [null, [Validators.required]],
      observaciones: [null, [Validators.required, Validators.maxLength(400)]],
    });

    // Crear formulario Devolver A Concesionario
    this.formDevolverAConcesionario = formBuilder.group({
      observaciones: [null, [Validators.required]],
    });

    // Crear formulario Crear Abscisado
    this.formCrearAbscisa = formBuilder.group({
      tipoMargen: [null, [Validators.required]],
      abscisaInicial: [null],
      abscisaFinal: [null],
      longitudEfectiva: [null],
      abscisaInicialIzquierda: [null],
      abscisaFinalIzquierda: [null],
      longitudEfectivaIzquierda: [null],
      abscisaInicialDerecha: [null],
      abscisaFinalDerecha: [null],
      longitudEfectivaDerecha: [null],
    });
  }

  /**
   * Inicializa el componente
   */
  ngOnInit() {
    this.predio = {};
    this.predio.areaRemanente = '0';
    this.predio.areaPredioSobrante = '0';
    this.predio.propietarios = [];
    this.predio.abscisas = [];
    this.prdPropietarioACrear = {};
    this.prdPropietarioYaRegistrado = {};
    this.prdPropietarioYaRegistradoList = [];
    this.tipoPropietarioSelected = {};
    this.disponibilidadPredio = {};
    this.prdMargenPredioDTO = {};

    // Obtener el proyecto seleccionado
    const idParam = "id";
    const idPredioHistoricoParam = "idPredioHistorico";
    const idBorradorParam = "borrador";
    this.ruta.params.subscribe((params) => {
      this.idProyecto = params[idParam];
      this.idPredioHistorico = params[idPredioHistoricoParam];
      this.borrador = params[idBorradorParam] == "true"; //castear el parametro obtenido a boolean
    });

    this.isDisablePredioReportado = false;
    this.isDisableAreaRemanente = false;
    this.isDisablePredioSobrante = false;
    this.isDisablePredioArchivado = false;

    this.cargarInformacionPredio();

   
  }

  cargarInformacionPredio() {
    this.blockedDocument = true;

    // Inicializar listas parametricas y DTOs
    forkJoin(
      this.unidadFuncionalCarreteroService.getUnidadesFuncionalesPorSoloProyecto(
        this.idProyecto
      ),
      this.componentesComunesService.obtenerProyecto(this.idProyecto),
      this.prediosService.srvObtenerPredioPorIdYBorrador(
        this.idPredioHistorico,
        this.borrador
      ),
      this.prediosService.srvObtenerDisponibilidadPorPredio(
        this.idPredioHistorico,
        this.borrador
      ),
      this.prediosService.srvListarNaturalezaJuridica(),
      this.prediosService.srvListarProcesoAdquisicion(),
      this.prediosService.srvListarTipoDocumento(),
      this.prediosService.srvListarUsoSuelo(),
      this.parametricasService.getTrnSiNo(),
      this.prediosService.getDepartamentos(),
      this.parametricasService.getTrnUnidadMedidaPorTipo("Area"),
      this.prediosService.srvListarTipoMargenPredio()
    ).subscribe(
      ([
        unidadFuncionalData,
        proyectoData,
        predioData,
        disponibilidadPredioData,
        naturalezaJuridicaData,
        procesoAdquisicionData,
        tipoDocumentoData,
        usoSueloData,
        siNoData,
        departamentoData,
        unidadMedidaData,
        tipoMargenPredioData,
      ]) => {
        // console.log('unidadFuncionalData', unidadFuncionalData);
        console.log("predioData", predioData);
        console.log("unidadMedidaData", unidadMedidaData);

        const natural = {
          id: 1,
          nombre: "Natural",
        };
        const juridico = {
          id: 2,
          nombre: "Jurídico",
        };
        this.prdMaTipoPropietarioDTOList = [];
        this.prdMaTipoPropietarioDTOList.push(natural);
        this.prdMaTipoPropietarioDTOList.push(juridico);

        this.unidadesFuncionales = unidadFuncionalData.unidadesFuncionales;
        this.proyectoSeleccionado = proyectoData;

        //Obtener Predio
        this.predio = predioData.predio;
        this.predio.borrador = this.borrador;
        this.predio.idProyecto = this.idProyecto;
        if (this.predio.propietarios == null) {
          this.predio.propietarios = [];
        } else {
          this.calcularParticipacionSumatoria();
        }

        if (this.predio.abscisas == null) {
          this.predio.abscisas = [];
        }

        this.prdMaNaturalezaJuridicaDTOList =
          naturalezaJuridicaData.naturalezaJuridicas;
        this.prdMaProcesoAdquisicionDTOList =
          procesoAdquisicionData.procesosAdquisicion;
        this.prdMaTipoDocumentoDTOList = tipoDocumentoData.tiposDocumento;
        this.prdMaUsoSueloDTOList = usoSueloData.usosSuelo;
        this.sinoList = siNoData.sinoList;
        this.sinoList = this.sinoList.slice(1);

        this.trnMaUnidadMedidaDTOList = unidadMedidaData.unidadesMedida;
        this.prdMaTipoMargenPredioDTOList =
          tipoMargenPredioData.listaPrdMaTipoMargenPredio;

        // Cargar el objeto del dropdown UnidadFuncional
        this.predio.unidadFuncional = this.unidadesFuncionales.find(
          (t) => t.idUnidadFuncional === this.predio.idUnidadFuncional
        );

        // Simular que elegi un item del dropdown UnidadFuncional
        this.formPredio
          .get("unidadFuncional")
          .setValue(this.predio.unidadFuncional);
        this.onSelectUnidadFuncional(this.predio.unidadFuncional);

        // Cargar el objeto del dropdown Naturaleza Juridica
        this.predio.naturalezaJuridicaDTO =
          this.prdMaNaturalezaJuridicaDTOList.find(
            (t) => t.id === this.predio.idNaturalezaJuridica
          );

        if (this.predio.naturalezaJuridicaDTO != null) {
          this.colocarValidacionesPorNaturalezaJuridica(
            this.predio.naturalezaJuridicaDTO.id
          );
        }

        // Cargar el objeto del dropdown Departamento
        this.departamentos = departamentoData;
        // console.log('departamentos', this.departamentos);
        this.departamentoSeleccionado = this.departamentos.find(
          (t) => t.id === this.predio.idDepartamento
        );

        if (this.departamentoSeleccionado != null) {
          // Simular que elegi un item del dropdown Departamento
          this.formPredio
            .get("departamento")
            .setValue(this.departamentoSeleccionado);
          forkJoin(
            this.prediosService.getCiudadesPorDepartamento(
              this.departamentoSeleccionado.id
            )
          ).subscribe(
            ([ciudadesData]: any) => {
              // Cargar el objeto del dropdown Municipio
              // console.log('ciudadesss data', ciudadesData);
              this.ciudades = ciudadesData;
              this.ciudadSeleccionada = this.ciudades.find(
                (t) => t.id === this.predio.idMunicipio
              );
            },
            (error) => {
              console.error(error);
              this.principalComponent.cargarErrorServicio(error);
              this.messageService.add({
                severity: "error",
                summary: "Error",
                detail: error,
                life: 10000,
              });
            }
          );
        }

        // Cargar el objeto del dropdown Predio Requerido
        this.predio.predioRequeridoDTO = this.sinoList.find(
          (t) => t.valor === this.predio.predioRequerido
        );

        // Cargar el objeto del dropdown Predio Afectado
        this.predio.predioAfectadoDTO = this.sinoList.find(
          (t) => t.valor === this.predio.predioAfectado
        );

        // Cargar el objeto del dropdown Predio Ofertado
        this.predio.predioOfertadoDTO = this.sinoList.find(
          (t) => t.valor === this.predio.predioOfertado
        );

        if(this.predio.fechaRadicacionAni !== unidadFuncionalData && this.predio.fechaRadicacionAni !== null ){
          this.predio.fechaRadicacionAni = new Date(this.predio.fechaRadicacionAni);
        }else{
          this.predio.fechaRadicacionAni = new Date();
        }
        
        this.predio.predioReportadoFormatoNiifDTO = this.sinoList.find(
          (t) => t.valor === this.predio.predioReportadoFormatoNiif
        );

        if(!this.predio.predioReportadoFormatoNiif){
          this.isDisablePredioReportado = true;
          this.predio.trimestreAnioReporteNiif =  null;
        }else{
          this.isDisablePredioReportado = false;
        }

        this.predio.areaRemanenteNoDesarrollableDTO = this.sinoList.find(
          (t) => t.valor === this.predio.areaRemanenteNoDesarrollable
        );

        if(!this.predio.areaRemanenteNoDesarrollable){
          this.predio.areaRemanente = '0';
          this.isDisableAreaRemanente = true;
          this.formPredio.controls['areaRemanente'].disable();
        }else{
          this.isDisableAreaRemanente = false;
          this.formPredio.controls['areaRemanente'].enable();
        }

        this.predio.predioSobranteDTO = this.sinoList.find(
          (t) => t.valor === this.predio.predioSobrante
        );

        if(!this.predio.predioSobrante){
          this.predio.areaPredioSobrante = '0';
          this.isDisablePredioSobrante = true;    
          this.formPredio.controls['areaPredioSobrante'].disable();  
        }else{
          this.isDisablePredioSobrante = false;
          this.formPredio.controls['areaPredioSobrante'].enable();
        }

        this.predio.predioArchivadoFiisicoAniDTO = this.sinoList.find(
          (t) => t.valor === this.predio.predioArchivadoFiisicoAni
        );

        if(!this.predio.predioArchivadoFiisicoAni){
          this.isDisablePredioArchivado = true;
          this.predio.numeroRadicacionAni = null;
        }else{
          this.isDisablePredioArchivado = false;
        }

        // Cargar el objeto del dropdown Uso del Suelo
        this.predio.usoSueloDTO = this.prdMaUsoSueloDTOList.find(
          (t) => t.id === this.predio.idUsoSuelo
        );

        // Cargar el objeto del dropdown Unidad Medida
        console.log(
          "this.predio.idUnidadMedidaAreaTerrenoRequerido",
          this.predio.idUnidadMedidaAreaTerrenoRequerido
        );
        console.log(
          "this.trnMaUnidadMedidaDTOList",
          this.trnMaUnidadMedidaDTOList
        );

        this.predio.unidadMedidaAreaTerrenoRequeridoDTO =
          this.trnMaUnidadMedidaDTOList.find(
            (t) => t.id === this.predio.idUnidadMedidaAreaTerrenoRequerido
          );

        //Obtener Disponibilidad Predio
        this.disponibilidadPredio =
          disponibilidadPredioData.disponibilidadPredio;
        if (this.disponibilidadPredio != null) {
          this.disponibilidadPredio.fechaAdquirido = new Date(
            this.disponibilidadPredio.fechaAdquirido
          );
          this.disponibilidadPredio.fechaDisponible = new Date(
            this.disponibilidadPredio.fechaDisponible
          );
        } else {
          this.disponibilidadPredio = {};
        }

        // Cargar el objeto del dropdown PredioDisponible
        this.disponibilidadPredio.predioDisponibleDTO = this.sinoList.find(
          (t) => t.valor === this.disponibilidadPredio.predioDisponible
        );

        // Cargar el objeto del dropdown PredioAdquirido
        this.disponibilidadPredio.predioAdquiridoDTO = this.sinoList.find(
          (t) => t.valor === this.disponibilidadPredio.predioAdquirido
        );

        // Cargar el objeto del dropdown ProcesoAdquisicion
        this.disponibilidadPredio.procesoAdquisicionDTO =
          this.prdMaProcesoAdquisicionDTOList.find(
            (t) => t.id === this.disponibilidadPredio.idProcesoAdquisicion
          );

        this.blockedDocument = false;
      },
      (e) => {}
    );
  }

  onChangeNaturalezaJuridica(event) {
    console.log(event.value);
    this.colocarValidacionesPorNaturalezaJuridica(event.value.id);
  }

  onChangePredioReportado(event){
    if(event!== null && event.value!== null && !event.value.valor){
      this.isDisablePredioReportado = true;
      this.predio.trimestreAnioReporteNiif =  null;
    }else{
      this.isDisablePredioReportado = false;
    }
  }
  
  onChangeAreaRemanente(event){
    if(event!== null && event.value!== null && !event.value.valor){
      this.predio.areaRemanente = '0';
      this.isDisableAreaRemanente = true;
      this.formPredio.controls['areaRemanente'].disable();
    }else{
      this.isDisableAreaRemanente = false;
      this.formPredio.controls['areaRemanente'].enable();
    }
  }
  
  onChangePredioSobrante(event){
    if(event!== null && event.value!== null && !event.value.valor){
      this.predio.areaPredioSobrante = '0';
      this.isDisablePredioSobrante = true;    
      this.formPredio.controls['areaPredioSobrante'].disable();  
    }else{
      this.isDisablePredioSobrante = false;
      this.formPredio.controls['areaPredioSobrante'].enable();
    }
  }

  onChangePredioArchivado(event){
    if(event!== null && event.value!== null && !event.value.valor){
      this.isDisablePredioArchivado = true;
      this.predio.numeroRadicacionAni = null;
    }else{
      this.isDisablePredioArchivado = false;
    }
  }


  colocarValidacionesPorNaturalezaJuridica(idNaturalezaJuridica: number) {
    console.log("idNaturalezaJuridica", idNaturalezaJuridica);

    const folioMatriculaControl = this.formPredio.get("folioMatricula");

    if (idNaturalezaJuridica == 1) {
      console.log("baldio");
      folioMatriculaControl.setValidators([Validators.maxLength(100)]);
    } else if (idNaturalezaJuridica == 2) {
      console.log("ejido");
      folioMatriculaControl.setValidators([Validators.maxLength(100)]);
    } else if (idNaturalezaJuridica == 3) {
      console.log("privado");
      folioMatriculaControl.setValidators([
        Validators.required,
        Validators.maxLength(100),
      ]);
    } else if (idNaturalezaJuridica == 4) {
      console.log("publico");
      folioMatriculaControl.setValidators([
        Validators.required,
        Validators.maxLength(100),
      ]);
    }

    folioMatriculaControl.updateValueAndValidity();
  }

  cargarUnidadesFuncionales(event) {
    this.unidadesFuncionalesFiltered = [];
    let existeFiltro: boolean;
    for (let i = 0; i < this.unidadesFuncionales.length; i++) {
      let brand = this.unidadesFuncionales[i];
      let item: string = String(brand.nombre);
      if (item.toLowerCase().includes(event.query.toLowerCase())) {
        this.unidadesFuncionalesFiltered.push(brand);
        existeFiltro = true;
      }
    }
    if (existeFiltro) {
      this.unidadesFuncionales = this.unidadesFuncionalesFiltered;
    }
  }

  onSelectUnidadFuncional(event) {
    if (event != null) {
      this.cargarSubtramosPorUnidadFuncional(event.id);
    }
  }

  cargarSubtramosPorUnidadFuncional(idUnidadFuncional) {
    this.blockedDocument = true;
    forkJoin(
      this.unidadFuncionalCarreteroService.getListarSubtramosPorUnidadFuncional(
        idUnidadFuncional,
        false
      )
    ).subscribe(
      ([result]) => {
        // console.log(result);

        if (result != null && result.respuestaServicio != null) {
          //Si el resultado es exitoso...
          if (result.respuestaServicio.codigoSalida === 1) {
            this.subtramos = result.subtramos;

            // Cargar el objeto del dropdown Subtramo
            this.predio.subtramo = this.subtramos.find(
              (t) => t.idSubtramo === this.predio.idSubtramo
            );
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Error",
              detail: result.respuestaServicio.mensajeSalida,
              life: 10000,
            });
          }
        } else {
        }
      },
      (error) => {
        console.error(error);
        this.principalComponent.cargarErrorServicio(error);
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: error,
          life: 10000,
        });
      },
      () => {
        //Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  cargarDepartamentos(event) {
    this.filtroDepartamentos = [];
    let existeFiltro: boolean;
    for (let i = 0; i < this.departamentos.length; i++) {
      const filtro = this.departamentos[i];
      if (event != null) {
        if (
          String(filtro.nombre)
            .toLowerCase()
            .includes(event.query.toLowerCase())
        ) {
          this.filtroDepartamentos.push(filtro);
          existeFiltro = true;
        }
      }
    }
    if (existeFiltro) {
      this.departamentos = this.filtroDepartamentos;
    }
  }

  cargarFiltroDepartamentos(event) {
    this.filtroDepartamentos = [];
    for (let i = 0; i < this.departamentos.length; i++) {
      const filtro = this.departamentos[i];
      if (
        String(filtro.nombre).toLowerCase().includes(event.query.toLowerCase())
      ) {
        this.filtroDepartamentos.push(filtro);
      }
    }
  }

  limpiarCiudades(event) {
    this.ciudadSeleccionada = null;
  }

  cargarCiudades(event) {
    this.filtroCiudades = [];
    if (this.departamentoSeleccionado != null) {
      return this.prediosService
        .getCiudadesPorDepartamento(this.departamentoSeleccionado.id)
        .subscribe((data: any) => {
          this.ciudades = data;
          let existeFiltro: boolean;
          for (let i = 0; i < this.ciudades.length; i++) {
            const filtro = this.ciudades[i];
            if (event != null) {
              if (
                String(filtro.nombre)
                  .toLowerCase()
                  .includes(event.query.toLowerCase())
              ) {
                this.filtroCiudades.push(filtro);
                existeFiltro = true;
              }
            }
          }
          if (existeFiltro) {
            this.ciudades = this.filtroCiudades;
          }
        });
    }
  }

  cargarFiltroCiudades(event) {
    this.filtroCiudades = [];
    for (let i = 0; i < this.ciudades.length; i++) {
      const filtro = this.ciudades[i];
      if (
        String(filtro.nombre).toLowerCase().includes(event.query.toLowerCase())
      ) {
        this.filtroCiudades.push(filtro);
      }
    }
  }

  btnAgregarPropietario() {
    this.prdPropietarioYaRegistrado = null;
    this.porcentajeParticipacion = null;
    // Abrir el dialogo
    this.dialogoPropietarios = true;
    this.filtroDtPropietarios = null;
    this.dataTableComponent.reset();
  }

  btnCrearPropietario() {
    this.prdPropietarioACrear = {};
    this.tipoDocumentoSelected = null;
    // Abrir el dialogo
    this.dialogoCrearPropietario = true;
  }

  btnEditarPropietario(propietarioSelected: PrdPersonaDTO) {
    // Cargar el objeto del dropdown Tipo Propietario
    this.tipoPropietarioSelected = this.prdMaTipoPropietarioDTOList.find(
      (t) => t.id === propietarioSelected.idTipoPersona
    );
    forkJoin(
      this.prediosService.srvListarPersonasPorTipo(
        this.tipoPropietarioSelected.id
      )
    ).subscribe(
      ([personasData]: any) => {
        // Cargar el objeto de propietarios ya registrados
        this.prdPropietarioYaRegistradoList = personasData.personas;
        // Cargar el registro seleccionado de propietario
        this.prdPropietarioYaRegistrado =
          this.prdPropietarioYaRegistradoList.find(
            (t) => t.idPersona === propietarioSelected.idPersona
          );
        this.filtroDtPropietarios =
          this.prdPropietarioYaRegistrado.numeroIdentificacion;
        this.dataTableComponent.filterGlobal(
          this.filtroDtPropietarios.trim(),
          "contains"
        );
        this.cargarListadoPropietariosYaRegistrados(
          this.tipoPropietarioSelected.id
        );
        this.cargarListadoTiposDeDocumento(this.tipoPropietarioSelected.id);
        this.colocarValidacionesPorTipoPersona(this.tipoPropietarioSelected.id);
      },
      (e) => {}
    );
    this.porcentajeParticipacion =
      propietarioSelected.porcentajeParticipacion * 100;
    this.dialogoPropietarios = true;
  }

  btnEliminarPropietario(propietarioSelected: PrdPersonaDTO) {
    this.predio.propietarios = this.predio.propietarios.filter(
      (obj) => obj !== propietarioSelected
    );
    this.calcularParticipacionSumatoria();
  }

  onSelectTipoPropietario(event) {
    if (event != null) {
      // console.log(event.value);
      this.cargarListadoPropietariosYaRegistrados(event.value.id);
      this.cargarListadoTiposDeDocumento(event.value.id);
      this.colocarValidacionesPorTipoPersona(event.value.id);
    }
  }

  cargarListadoPropietariosYaRegistrados(idTipoPersona: number) {
    forkJoin(
      this.prediosService.srvListarPersonasPorTipo(idTipoPersona)
    ).subscribe(
      ([personasData]: any) => {
        // Cargar el objeto de propietarios ya registrados
        this.prdPropietarioYaRegistradoList = personasData.personas;
      },
      (e) => {}
    );
  }

  cargarListadoTiposDeDocumento(idTipoPersona: number) {
    if (idTipoPersona == 1) {
      forkJoin(
        this.parametricasService.getTrnTiposDocumentoPorIds(
          EnumTipoDocumento.CC +
            "," +
            EnumTipoDocumento.CE +
            "," +
            EnumTipoDocumento.RC +
            "," +
            EnumTipoDocumento.TI
        )
      ).subscribe(([tiposDocumentoData]: any) => {
        this.trnTiposDocumentoList = tiposDocumentoData;
      });
    } else if (idTipoPersona == 2) {
      forkJoin(
        this.parametricasService.getTrnTiposDocumentoPorIds(
          EnumTipoDocumento.NIT
        )
      ).subscribe(([tiposDocumentoData]: any) => {
        this.trnTiposDocumentoList = tiposDocumentoData;
      });
    } else {
      this.trnTiposDocumentoList = [];
    }
  }

  colocarValidacionesPorTipoPersona(idTipoPersona: number) {
    const primerNombreControl = this.formCrearPropietario.get("primerNombre");
    const otrosNombresControl = this.formCrearPropietario.get("otrosNombres");
    const primerApellidoControl =
      this.formCrearPropietario.get("primerApellido");
    const segundoApellidoControl =
      this.formCrearPropietario.get("segundoApellido");
    const razonSocialControl = this.formCrearPropietario.get("razonSocial");
    if (idTipoPersona == 1) {
      primerNombreControl.setValidators([
        Validators.required,
        Validators.maxLength(61),
      ]);
      otrosNombresControl.setValidators([Validators.maxLength(40)]);
      primerApellidoControl.setValidators([
        Validators.required,
        Validators.maxLength(30),
      ]);
      segundoApellidoControl.setValidators([
        Validators.required,
        Validators.maxLength(30),
      ]);
      razonSocialControl.setValidators(null);
    } else if (idTipoPersona == 2) {
      primerNombreControl.setValidators(null);
      otrosNombresControl.setValidators(null);
      primerApellidoControl.setValidators(null);
      segundoApellidoControl.setValidators(null);
      razonSocialControl.setValidators([
        Validators.required,
        Validators.maxLength(61),
      ]);
    }
    primerNombreControl.updateValueAndValidity();
    primerApellidoControl.updateValueAndValidity();
    segundoApellidoControl.updateValueAndValidity();
    razonSocialControl.updateValueAndValidity();
  }

  /**
   * Agrega el propietario elegido al listado
   */
  submitAgregarPropietario() {
    console.log(this.formAgregarPropietarios);

    if (this.formAgregarPropietarios.valid) {
      if (this.prdPropietarioYaRegistrado != null) {
        this.prdPropietarioYaRegistrado.porcentajeParticipacion =
          this.porcentajeParticipacion / 100;

        // Cargar el objeto del dropdown Naturaleza Juridica
        let propietarioEncontrado = this.predio.propietarios.find(
          (t) =>
            t.idPersona === this.prdPropietarioYaRegistrado.idPersona &&
            t.idTipoPersona === this.prdPropietarioYaRegistrado.idTipoPersona
        );

        if (propietarioEncontrado != null) {
          //Eliminar el propietario encontrado
          this.predio.propietarios = this.predio.propietarios.filter(
            (obj) => obj !== propietarioEncontrado
          );
          this.lanzarMensajeInfo("El propietario se actualizó correctamente");
        }

        this.predio.propietarios.push(this.prdPropietarioYaRegistrado);
        this.calcularParticipacionSumatoria();
        this.dialogoPropietarios = false;
      } else {
        this.lanzarMensajeWarning("Debe seleccionar un propietario registrado");
      }
    } else {
      this.lanzarMensajeWarning(
        "Debe diligenciar los campos requeridos marcados con asterisco (*)"
      );
    }
  }

  calcularParticipacionSumatoria() {
    this.participacionSumatoria = 0;

    for (let index = 0; index < this.predio.propietarios.length; index++) {
      const propietarioVar = this.predio.propietarios[index];
      const porcentaFormat = parseFloat(
        propietarioVar.porcentajeParticipacion.toFixed(4)
      );

      this.participacionSumatoria += porcentaFormat * 100;
    }
  }

  /**
   * Guarda un nuevo propietario en base de datos
   */
  submitGuardarPropietario() {
    // console.log(this.formCrearPropietario);
    if (this.formCrearPropietario.valid) {
      // Completar el DTO a guardar
      this.prdPropietarioACrear.idTipoDocumento = this.tipoDocumentoSelected.id;
      this.prdPropietarioACrear.idTipoPersona = this.tipoPropietarioSelected.id;

      if (this.prdPropietarioACrear.idTipoPersona == 1) {
        this.prdPropietarioACrear.razonSocial = null;
      } else if (this.prdPropietarioACrear.idTipoPersona == 2) {
        this.prdPropietarioACrear.primerNombre = null;
        this.prdPropietarioACrear.otrosNombres = null;
        this.prdPropietarioACrear.primerApellido = null;
        this.prdPropietarioACrear.segundoApellido = null;
      }

      // Guardar el DTO
      forkJoin(
        this.prediosService.srvGuardarPersona(this.prdPropietarioACrear)
      ).subscribe(
        ([result]) => {
          // console.log(result);
          if (result != null && result.respuestaServicio != null) {
            // Si el resultado es exitoso...
            if (result.respuestaServicio.codigoSalida === 1) {
              // Consultar nuevamente la lista
              this.cargarListadoPropietariosYaRegistrados(
                this.tipoPropietarioSelected.id
              );

              // Cargar el objeto del dropdown Tipo Propietario
              this.tipoPropietarioSelected =
                this.prdMaTipoPropietarioDTOList.find(
                  (t) => t.id === this.prdPropietarioACrear.idTipoPersona
                );

              //Cargar el filtro propietarios
              this.filtroDtPropietarios =
                this.prdPropietarioACrear.numeroIdentificacion;
              this.dataTableComponent.filterGlobal(
                this.filtroDtPropietarios,
                "contains"
              );

              // Lanzar mensaje de exito
              this.messageService.add({
                severity: "info",
                summary: "Información",
                detail: result.respuestaServicio.mensajeSalida,
                life: 10000,
              });
            } else {
              // console.log(result.respuestaServicio.mensajeSalida);
              this.lanzarMensajeWarning(result.respuestaServicio.mensajeSalida);
            }
          } else {
            // console.log('Ocurrio un error al consumir el servicio srvGuardarBien');
          }
        },
        (error) => {
          // console.log(error);
        },
        () => {
          // Desbloquear pantalla
          this.dialogoCrearPropietario = false;
        }
      );
    }
  }

  btnAgregarAbscisa() {
    // Abrir el dialogo
    this.dialogoAbscisado = true;
    this.prdMargenPredioDTO = {};
    this.editarMargenPredio = false;
    this.esConsultaMargenPredio = false;
    this.tipoMargenPredioSelected = null;
  }

  btnEditarAbscisa(
    abscisaSelected: PrdMargenPredioDTO,
    index,
    esConsultaMargenPredio: boolean
  ) {
    this.esConsultaMargenPredio = esConsultaMargenPredio;
    this.indexMargenPredio = index;
    this.editarMargenPredio = true;
    console.log(abscisaSelected);
    console.log(index);
    this.dialogoAbscisado = true;
    this.prdMargenPredioDTO = abscisaSelected;
    var valor = this.prdMargenPredioDTO.idTipoMargen;
    if (valor === 1 || valor === 2) {
      this.visibleDobleSentidoAbscisado = false;
    } else {
      this.visibleDobleSentidoAbscisado = true;
    }
    this.tipoMargenPredioSelected =
      this.prdMargenPredioDTO.prdMaTipoMargenPredio;
  }

  irEliminarAbscisa(abscisaSelected: PrdMargenPredioDTO, index) {
    this.indexMargenPredio = index;
    this.dialogoEliminarAbscisa = true;
  }

  btnEliminarAbscisa() {
    var removed = this.predio.abscisas.splice(this.indexMargenPredio, 1);
    console.log("After removing 1: " + this.predio.abscisas);
    console.log("removed is: " + removed);

    this.lanzarMensajeInfo("Se eliminó el registro correctamente.");
    this.dialogoEliminarAbscisa = false;
  }

  submitGuardarAbscisa() {
    this.prdMargenPredioDTO.idTipoMargen = this.tipoMargenPredioSelected.id;
    this.prdMargenPredioDTO.tipoMargen = this.tipoMargenPredioSelected.nombre;
    this.prdMargenPredioDTO.prdMaTipoMargenPredio =
      this.tipoMargenPredioSelected;
    if (!this.editarMargenPredio) {
      console.log(this.prdMargenPredioDTO);
      this.lanzarMensajeInfo("Se agregó el registro correctamente.");
      this.predio.abscisas.push(this.prdMargenPredioDTO);
      this.dialogoAbscisado = false;
    } else {
      var removed = this.predio.abscisas.splice(
        this.indexMargenPredio,
        0,
        this.prdMargenPredioDTO
      );
      console.log("After adding 1: " + this.predio.abscisas);
      console.log("removed is: " + removed);

      removed = this.predio.abscisas.splice(this.indexMargenPredio + 1, 1);
      console.log("After removing 1: " + this.predio.abscisas);
      console.log("removed is: " + removed);
      this.lanzarMensajeInfo("Se edito el registro correctamente.");
      this.dialogoAbscisado = false;
    }
  }

  onSelectTipoMargen(event) {
    if (event != null) {
      var valor = event.value.id;
      if (valor === 1 || valor === 2) {
        this.visibleDobleSentidoAbscisado = false;
      } else {
        this.visibleDobleSentidoAbscisado = true;
      }
    }
  }

  guardarPredio() {
    if (this.predio.borrador) {
      this.submitGuardarPredio();
    } else {
      if (this.formPredio.valid) {
        this.dialogoCrearBorradorGuardarPredio = true;
      } else {
        this.lanzarMensajeWarning(
          "Debe diligenciar los campos requeridos marcados con asterisco (*)"
        );
      }
    }
  }

  /**
   * Guarda los cambios al crear o editar un predio
   */
  submitGuardarPredio() {
    if (this.formPredio.valid) {
      // Completar el DTO a guardar
      this.predio.idUnidadFuncional =
        this.predio.unidadFuncional.idUnidadFuncional;
      if (this.predio.subtramo != null) {
        this.predio.idSubtramo = this.predio.subtramo.idSubtramo;
      }
      this.predio.idNaturalezaJuridica = this.predio.naturalezaJuridicaDTO.id;
      this.predio.idDepartamento = this.departamentoSeleccionado.id;
      this.predio.idMunicipio = this.ciudadSeleccionada.id;
      this.predio.idUsoSuelo = this.predio.usoSueloDTO.id;
      this.predio.idUnidadMedidaAreaTerrenoRequerido =
        this.predio.unidadMedidaAreaTerrenoRequeridoDTO.id;
      this.predio.predioRequerido = Boolean(
        this.predio.predioRequeridoDTO.valor
      );
      this.predio.predioAfectado = Boolean(this.predio.predioAfectadoDTO.valor);
      this.predio.predioOfertado = Boolean(this.predio.predioOfertadoDTO.valor);
      this.predio.predioReportadoFormatoNiif = Boolean(this.predio.predioReportadoFormatoNiifDTO.valor);
      this.predio.areaRemanenteNoDesarrollable = Boolean(this.predio.areaRemanenteNoDesarrollableDTO.valor);
      this.predio.predioSobrante = Boolean(this.predio.predioSobranteDTO.valor);
      this.predio.predioArchivadoFiisicoAni = Boolean(this.predio.predioArchivadoFiisicoAniDTO.valor);
      this.predio.borrador = true;
      this.borrador = true;

      this.reglasCampoAreaTerrenoRequerido = true;
      if (
        (this.predio.idUnidadMedidaAreaTerrenoRequerido == 4 &&
          this.predio.areaTerrenoRequerido > 20) ||
        this.predio.areaTerrenoRequerido == 0
      ) {
        this.reglasCampoAreaTerrenoRequerido = false;
      }
      if (
        (this.predio.idUnidadMedidaAreaTerrenoRequerido == 5 &&
          this.predio.areaTerrenoRequerido > 200000) ||
        this.predio.areaTerrenoRequerido == 0
      ) {
        this.reglasCampoAreaTerrenoRequerido = false;
      }

      if(this.predio.predioReportadoFormatoNiifDTO != null && this.predio.predioReportadoFormatoNiifDTO.valor === true){
        if(this.predio.trimestreAnioReporteNiif === undefined || this.predio.trimestreAnioReporteNiif === null || this.predio.trimestreAnioReporteNiif ==="" ){
          this.lanzarMensajeWarning("Debe ingresar un valor en el Trimestre del año que reporte NIIF");
          return;
        }
      }

      if(this.predio.areaRemanenteNoDesarrollableDTO != null && this.predio.areaRemanenteNoDesarrollableDTO.valor === true){
        if(this.predio.areaRemanente !== undefined && this.predio.areaRemanente !== null && Number(parseFloat(this.predio.areaRemanente).toFixed(6)) === 0){
          this.lanzarMensajeWarning("Debe ingresar un valor mayor que cero en el Área remanente en metros cuadrados");
          return;
        }else{
          if(this.predio.areaRemanente === undefined || this.predio.areaRemanente === null){
            this.lanzarMensajeWarning("Debe ingresar un valor mayor que cero en el Área remanente en metros cuadrados");
            return;
          }          
        }
      }

      if(this.predio.predioSobranteDTO != null && this.predio.predioSobranteDTO.valor === true){
        if(this.predio.areaPredioSobrante !== undefined && this.predio.areaPredioSobrante !== null && Number(parseFloat(this.predio.areaPredioSobrante).toFixed(6)) === 0 ){
          this.lanzarMensajeWarning("Debe ingresar un valor mayor que cero en el Área Predio Sobrante");
          return;
        }else{
          if(this.predio.areaPredioSobrante === undefined || this.predio.areaPredioSobrante === null){
            this.lanzarMensajeWarning("Debe ingresar un valor mayor que cero en el Área remanente en metros cuadrados");
            return;
          }          
        }
      }
      
      if(this.predio.predioArchivadoFiisicoAniDTO != null && this.predio.predioArchivadoFiisicoAniDTO.valor === true){
        if(this.predio.numeroRadicacionAni !== undefined && this.predio.numeroRadicacionAni !== null && Math.ceil(this.predio.numeroRadicacionAni) <= 0 ){
          this.lanzarMensajeWarning("Debe ingresar un valor en el Número de Radicación");
          return;
        }else{
          if(this.predio.numeroRadicacionAni === undefined || this.predio.numeroRadicacionAni === null){
             this.lanzarMensajeWarning("Debe ingresar un valor en el Número de Radicación");
             return;
          }
        }
        if(Number(this.predio.fechaRadicacionAni.getFullYear()) < 2020 ){
          this.lanzarMensajeWarning("Debe ingresar un valor mayor igual al año 2020 en la Fecha de Radicación");
          return;
        }
      }else{
        this.predio.fechaRadicacionAni = null;
      }


      if (this.reglasCampoAreaTerrenoRequerido) {
        if (this.predio.propietarios.length == 0) {
          this.lanzarMensajeWarning("Debe asociar por lo menos un propietario");
        } else {
          if (this.participacionSumatoria != 100) {
            this.lanzarMensajeWarning(
              "La participación total de los propietarios debe ser del 100% "
            );
          } else {
            if(this.predio.predioArchivadoFiisicoAniDTO != null && this.predio.predioArchivadoFiisicoAniDTO.valor === false){
              this.predio.fechaRadicacionAni = null;
            }
            this.blockedDocument = true;
            // Guardar el DTO
            forkJoin(
              this.prediosService.srvGuardarPredio(this.predio),
              this.prediosService.sincronizarPredio(this.predio.fichaPredial)
            ).subscribe(
              ([result, sincronizacionData]) => {
                console.log("sincronizacionData PREDIO", sincronizacionData);
                // console.log(result);
                if (result != null && result.respuestaServicio != null) {
                  // Si el resultado es exitoso...
                  if (result.respuestaServicio.codigoSalida === 1) {
                    //Asignar el id del predio recien guardado
                    this.predio.idPredioHistorico =
                      result.predio.idPredioHistorico;
                    this.lanzarMensajeInfo(
                      "Los cambios se guardaron correctamente"
                    );
                    if(this.predio.predioArchivadoFiisicoAniDTO != null && this.predio.predioArchivadoFiisicoAniDTO.valor === false){
                      this.predio.fechaRadicacionAni = new Date();
                    }
                  } else {
                    this.lanzarMensajeWarning(
                      result.respuestaServicio.mensajeSalida
                    );
                    if(this.predio.predioArchivadoFiisicoAniDTO != null && this.predio.predioArchivadoFiisicoAniDTO.valor === false){
                      this.predio.fechaRadicacionAni = new Date();
                    }
                  }
                } else {
                  // console.log('Ocurrio un error al consumir el servicio srvGuardarPredio');
                }
              },
              (error) => {
                // console.log(error);
              },
              () => {
                // Desbloquear pantalla
                this.blockedDocument = false;
                this.dialogoCrearBorradorGuardarPredio = false;
                if(this.predio.predioArchivadoFiisicoAniDTO != null && this.predio.predioArchivadoFiisicoAniDTO.valor === false){
                  this.predio.fechaRadicacionAni = new Date();
                }
              }
            );
          }
        }
      } else {
        if (this.predio.idUnidadMedidaAreaTerrenoRequerido == 5) {
          this.lanzarMensajeWarning(
            "Los valores permitos del campo Área Terreno Requerido son  mayores a 0  y menores o iguales a 200000  metros cuadrados"
          );
        }
        if (this.predio.idUnidadMedidaAreaTerrenoRequerido == 4) {
          this.lanzarMensajeWarning(
            "Los valores permitos del campo Área Terreno Requerido son  mayores a 0  y menores o iguales a 20 hectáreas"
          );
        }
      }
    } else {
      this.lanzarMensajeWarning(
        "Debe diligenciar los campos requeridos marcados con asterisco (*)"
      );
    }
  }

  onSelectPredioDisponible(event) {
    if (event != null) {
      // console.log(event.value.valor);
      const fechaDisponibilidadControl = this.formDisponibilidad.get(
        "fechaDisponibilidad"
      );
      const predioAdquiridoControl =
        this.formDisponibilidad.get("predioAdquirido");
      const fechaAdquiridoControl =
        this.formDisponibilidad.get("fechaAdquirido");
      if (event.value.valor) {
        fechaDisponibilidadControl.setValidators([Validators.required]);
        predioAdquiridoControl.setValidators([Validators.required]);
      } else {
        fechaDisponibilidadControl.setValue(null);
        predioAdquiridoControl.setValue(null);
        fechaAdquiridoControl.setValue(null);
        fechaDisponibilidadControl.setValidators([]);
        predioAdquiridoControl.setValidators([]);
        fechaAdquiridoControl.setValidators([]);
      }
      fechaDisponibilidadControl.updateValueAndValidity();
      predioAdquiridoControl.updateValueAndValidity();
      fechaAdquiridoControl.updateValueAndValidity();
    }
  }

  onSelectPredioAdquirido(event) {
    if (event != null) {
      // console.log(event.value.valor);
      const fechaAdquiridoControl =
        this.formDisponibilidad.get("fechaAdquirido");
      if (event.value.valor) {
        fechaAdquiridoControl.setValidators([Validators.required]);
      } else {
        fechaAdquiridoControl.setValue(null);
        fechaAdquiridoControl.setValidators([]);
      }
      fechaAdquiridoControl.updateValueAndValidity();
    }
  }

  guardarDisponibilidad() {
    if (this.predio.borrador) {
      this.submitGuardarDisponibilidad();
    } else {
      if (this.formDisponibilidad.valid) {
        this.dialogoCrearBorradorGuardarDisponibilidad = true;
      } else {
        this.lanzarMensajeWarning(
          "Debe diligenciar los campos requeridos marcados con asterisco (*)"
        );
      }
    }
  }

  /**
   * Guarda los cambios de la Disponibilidad del predio
   */
  submitGuardarDisponibilidad() {
    // console.log(this.form.value);
    if (this.formDisponibilidad.valid) {
      // Completar el DTO a guardar
      this.disponibilidadPredio.idPredioHistorico =
        this.predio.idPredioHistorico;
      this.disponibilidadPredio.idProcesoAdquisicion =
        this.disponibilidadPredio.procesoAdquisicionDTO.id;
      if (this.disponibilidadPredio.predioDisponibleDTO != null) {
        this.disponibilidadPredio.predioDisponible = Boolean(
          this.disponibilidadPredio.predioDisponibleDTO.valor
        );
      }
      if (this.disponibilidadPredio.predioAdquiridoDTO != null) {
        this.disponibilidadPredio.predioAdquirido = Boolean(
          this.disponibilidadPredio.predioAdquiridoDTO.valor
        );
      }

      this.disponibilidadPredio.borrador = this.borrador;

      // Guardar el DTO
      forkJoin(
        this.prediosService.srvGuardarDiponibilidadPredio(
          this.disponibilidadPredio
        )
      ).subscribe(
        ([result]) => {
          // console.log(result);
          if (result != null && result.respuestaServicio != null) {
            // Si el resultado es exitoso...
            if (result.respuestaServicio.codigoSalida === 1) {
              this.lanzarMensajeInfo("Los cambios se guardaron correctamente");
            } else {
              // console.log(result.respuestaServicio.mensajeSalida);
              this.lanzarMensajeWarning(result.respuestaServicio.mensajeSalida);
            }
          } else {
            // console.log('Ocurrio un error al consumir el servicio srvGuardarDiponibilidadPredio');
          }
        },
        (error) => {
          // console.log(error);
        },
        () => {
          // Desbloquear pantalla
          this.blockedDocument = false;
          this.dialogoCrearBorradorGuardarDisponibilidad = false;
        }
      );
    } else {
      this.lanzarMensajeWarning(
        "Debe diligenciar los campos requeridos marcados con asterisco (*)"
      );
    }
  }

  publicarPredio() {
    if (this.formPredio.valid) {
      this.dialogoPublicarPredio = true;
    } else {
      this.lanzarMensajeWarning(
        "Debe diligenciar los campos requeridos marcados con asterisco (*)"
      );
    }
  }

  notificarAInterventoria() {
    if (this.formPredio.valid) {
      console.log("notificarAInterventoria");
      console.log(this.disponibilidadPredio);
      this.dialogoNotificarAInterventoria = true;
    } else {
      this.lanzarMensajeWarning(
        "Debe diligenciar los campos requeridos marcados con asterisco (*)"
      );
    }
  }

  submitNofiticarAInterventoria() {
    this.blockedDocument = true;
    this.dialogoNotificarAInterventoria = false;
    // Guardar el DTO
    forkJoin(
      this.prediosService.srvNotificarInterventoria(this.predio)
    ).subscribe(
      ([result]) => {
        console.log("srvNotificarInterventoria");
        console.log(result);
        if (result != null && result.respuestaServicio != null) {
          // Si el resultado es exitoso...
          if (result.respuestaServicio.codigoSalida === 1) {
            //Asignar el id del predio recien guardado
            this.predio = result.predio;
            console.log("-----------this.predio", this.predio);
            this.cargarInformacionPredio();
            this.lanzarMensajeInfo("Los cambios se guardaron correctamente");
          } else {
            this.lanzarMensajeWarning(result.respuestaServicio.mensajeSalida);
          }
        } else {
          // console.log('Ocurrio un error al consumir el servicio srvGuardarPredio');
        }
      },
      (error) => {
        // console.log(error);
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  devolverAConcesionario() {
    console.log("devolverAConcesionario");
    this.dialogoDevolverAConcesionario = true;
  }

  submitDevolverAConcesionario() {
    if (this.formDevolverAConcesionario.valid) {
      this.blockedDocument = true;
      this.dialogoDevolverAConcesionario = false;
      this.predio.notificadoAInterventoria = false;
      // Guardar el DTO
      forkJoin(this.prediosService.srvDevolverPredio(this.predio)).subscribe(
        ([result]) => {
          console.log("srvDevolverPredio");
          console.log(result);
          if (result != null && result.respuestaServicio != null) {
            // Si el resultado es exitoso...
            if (result.respuestaServicio.codigoSalida === 1) {
              //Asignar el id del predio recien guardado
              this.predio.idPredioHistorico = result.predio.idPredioHistorico;
              this.lanzarMensajeInfo("Los cambios se guardaron correctamente");
              this.idPredioHistorico = this.predio.idPredioHistorico;
              this.borrador = true;
              this.cargarInformacionPredio();
            } else {
              this.lanzarMensajeWarning(result.respuestaServicio.mensajeSalida);
            }
          } else {
            // console.log('Ocurrio un error al consumir el servicio srvGuardarPredio');
          }
        },
        (error) => {
          // console.log(error);
        },
        () => {
          // Desbloquear pantalla
          this.blockedDocument = false;
        }
      );
    } else {
      this.lanzarMensajeWarning(
        "Debe diligenciar los campos requeridos marcados con asterisco (*)"
      );
    }
  }

  submitPublicarPredio() {
    // console.log("Publicar Predio !!!");
    this.blockedDocument = true;
    this.dialogoPublicarPredio = false;
    forkJoin(
      this.prediosService.srvPublicarPredio(
        this.predio.idPredio,
        this.idProyecto
      )
    ).subscribe(
      ([result]) => {
        // console.log(result);
        if (result != null && result.respuestaServicio != null) {
          // Si el resultado es exitoso...
          if (result.respuestaServicio.codigoSalida === 1) {
            // Consultar nuevamente la lista
            this.lanzarMensajeInfo("El predio se publicó correctamente");
            this.bntVolver();
          } else {
            this.lanzarMensajeWarning(result.respuestaServicio.mensajeSalida);
          }
        } else {
          // console.log('Ocurrio un error al consumir el servicio srvPublicarPredio');
        }
      },
      (error) => {
        // console.log(error);
      },
      () => {
        // Desbloquear pantalla
        this.blockedDocument = false;
      }
    );
  }

  bntVolver() {
    const ruta =
      "carreteros/infGeneral/proyectoHome/" +
      this.idProyecto +
      "/predios/" +
      this.idProyecto +
      "/registro-detallado-predios/" +
      this.idProyecto;
    this.router.navigate([ruta]);
  }

  // // // // /Mensajes// // // // //
  /**
   * Lanza mensaje de informacion
   */
  lanzarMensajeInfo(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "success",
      summary: "Información",
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de error
   */
  lanzarMensajeError(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "error",
      summary: "Error",
      detail: mensaje,
    });
  }

  /**
   * Lanza mensaje de advertencia
   */
  lanzarMensajeWarning(mensaje: string) {
    this.limpiarMensajes();
    this.messageService.add({
      severity: "warn",
      summary: "Advertencia",
      detail: mensaje,
    });
  }

  limpiarMensajes() {
    this.messageService.clear();
  }
}
