import { Component, OnInit } from '@angular/core';
import { InformeContratistaService } from '../informe-contratista.service';
import { Router, ActivatedRoute } from '@angular/router';
import { InformeContratistaBaseComponent } from '../informe-contratista-base/informe-contratista-base.component';
import { CtoInformeContratistaObligacionesDTO } from '../../../../contratacion-dto/informe-contratista-obligaciones';
import { MessageService } from 'primeng/api';
import { CtoAdjuntoInformeBorradorDTO } from '../../../../contratacion-dto/adjunto-informe-borrador';
import { forkJoin } from 'rxjs';
import { PrincipalComponent } from 'src/app/home/principal/principal.component';
import { EnumRespuestaServicio } from '../../../../../comun/constantes/constantes-comunes';

@Component({
  selector: 'app-informe-contratista-obligaciones',
  templateUrl: './informe-contratista-obligaciones.component.html',
  styles: []
})
export class InformeContratistaObligacionesComponent implements OnInit {

  private obligaciones: any = [];
  visibleDialogoSubirArchivo = false;
  informeObligacion: CtoInformeContratistaObligacionesDTO = {};
  archivos: any = [];
  archivo: any;
  visibleVisualizador = false;
  rowIndex: number;
  rutaArchivoObligacion: string;
  visibleDialogoVerEvidencia = false;
  adjuntosList: any = [];
  idActividad: number;
  idAdjuntoEliminar: number;
  visibleDialogoEliminarAdjunto = false;

  constructor(
    public lineaBase: InformeContratistaBaseComponent,
    private route: Router,
    private ruta: ActivatedRoute,
    private informeContratistaService: InformeContratistaService,
    private messageService: MessageService,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    this.cargarObligaciones(this.lineaBase.idContrato, this.lineaBase.idInforme);
  }

  /**
   * Metodo que llama el servicio de cargar las obligaciones contractuales versus las actividades relacionadas en el informe
   * @param idContrato Id del Contrato
   * @param idInforme Id del Informe
   * @returns Listado de obligaciones contractuales relacionadas con las actividades
   */
  cargarObligaciones(idContrato, idInforme) {
    this.lineaBase.blockedDocument = true;
    return this.informeContratistaService.srvGetActividadObligacion(idContrato, idInforme, this.lineaBase.esBorrador).subscribe(
      result => {
        if (result != null && result.respuestaServicio != null) {
          if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
            this.obligaciones = result.obligacionesActividades;
          } else {
            this.lineaBase.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
          }
        }
      },
      error => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        this.lineaBase.blockedDocument = false;
      }
    );
  }

  /**
   * Metodo que valida y guarda la información de actividades relacionadas a las obligaciones contractuales asociados en el informe
   * @param obligacion Infgormación de ActividadObligacion a guardar
   */
  guardarActividad(obligacion) {
    this.lineaBase.blockedDocument = true;
    if (obligacion.actividad == null || obligacion.actividad == '') {
      this.lineaBase.lanzarMensajeError('No registró valor para la actividad');
      this.lineaBase.blockedDocument = false;
    } else {
      obligacion.rutaSoporte = "";
      obligacion.idInforme = this.lineaBase.idInforme;
      if (this.lineaBase.esBorrador) {
        this.informeContratistaService.srvGuardarActividadObligacionBorrador(obligacion).subscribe(
          result => {
            if (result != null && result.respuestaServicio != null) {
              if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                this.lineaBase.lanzarMensajeInfo('La actividad se guardó exitosamente.');
              } else {
                this.lineaBase.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
              }
            }
          },
          error => {
            this.principalComponent.cargarErrorServicio(error);
          },
          () => {
            this.cargarObligaciones(this.lineaBase.idContrato, this.lineaBase.idInforme);
            this.lineaBase.blockedDocument = false;
          }
        );
      } else {
        this.informeContratistaService.srvGuardarActividadObligacion(obligacion).subscribe(
          result => {
            if (result != null && result.respuestaServicio != null) {
              if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                this.lineaBase.lanzarMensajeInfo('La actividad se guardó exitosamente.');
              } else {
                this.lineaBase.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
              }
            }
          },
          error => {
            this.principalComponent.cargarErrorServicio(error);
          },
          () => {
            this.cargarObligaciones(this.lineaBase.idContrato, this.lineaBase.idInforme);
            this.lineaBase.blockedDocument = false;
          }
        );
      }
    }
  }

  aceptarAdjuntoEnvidencia() {
    this.lineaBase.blockedDocument = true;
    if (this.archivos.length == 0) {
      this.lineaBase.lanzarMensajeError('Debe subir por lo menos un archivo para continuar.');
    } else {
      const adjuntosBorradorList: CtoAdjuntoInformeBorradorDTO[] = [];
      this.archivos.forEach(element => {
        const adjuntoBorrador: CtoAdjuntoInformeBorradorDTO = {};
        adjuntoBorrador.idActividad = this.obligaciones[this.rowIndex].id;
        adjuntoBorrador.nombreArchivoUsuario = element.nombre;
        adjuntoBorrador.rutaSoporte = element.nombreCompleto;
        adjuntosBorradorList.push(adjuntoBorrador);
      });

      forkJoin(
        this.informeContratistaService.getAdjuntosPorObligacion(this.obligaciones[this.rowIndex].id, this.lineaBase.esBorrador)
      ).subscribe(
        ([result]) => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.adjuntosList = result.adjuntos;
              const tamanioFinalAdjuntos: number = this.archivos.length + this.adjuntosList.length;
              if (tamanioFinalAdjuntos <= 3) {
                if (this.lineaBase.esBorrador) {
                  this.informeContratistaService.srvGuardarAdjuntoInformeBorrador(adjuntosBorradorList).subscribe(
                    result => {
                      if (result != null && result.respuestaServicio != null) {
                        if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                          this.lineaBase.lanzarMensajeInfo('Los adjuntos se guardaron exitosamente');
                        } else {
                          this.lineaBase.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
                        }
                      }
                    },
                    error => {
                      this.principalComponent.cargarErrorServicio(error);
                    },
                    () => {
                      this.cargarObligaciones(this.lineaBase.idContrato, this.lineaBase.idInforme);
                      this.lineaBase.blockedDocument = false;
                    }
                  );
                } else {
                  this.informeContratistaService.srvGuardarAdjuntoInforme(adjuntosBorradorList).subscribe(
                    result => {
                      if (result != null && result.respuestaServicio != null) {
                        if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
                          this.lineaBase.lanzarMensajeInfo('Los adjuntos se guardaron exitosamente');
                        } else {
                          this.lineaBase.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
                        }
                      }
                    },
                    error => {
                      this.principalComponent.cargarErrorServicio(error);
                    },
                    () => {
                      this.cargarObligaciones(this.lineaBase.idContrato, this.lineaBase.idInforme);
                      this.lineaBase.blockedDocument = false;
                    }
                  );
                }
              } else {
                this.lineaBase.lanzarMensajeError('Solo se permiten 3 archivos máximo por obligación.');
              }
            } else {
              this.lineaBase.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            }
            this.visibleDialogoSubirArchivo = false;
            this.archivos = [];
          }
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.lineaBase.blockedDocument = false;
        }
      );


    }
  }

  irVerDialogoVerAdjuntos(_obligacion) {
    this.visibleDialogoVerEvidencia = true;
    this.idActividad = _obligacion.id;
    this.cargarAdjuntosPorObligacion(this.idActividad);
  }

  cargarAdjuntosPorObligacion(id) {
    this.adjuntosList = [];
    forkJoin(
      this.informeContratistaService.getAdjuntosPorObligacion(id, this.lineaBase.esBorrador)
    ).subscribe(([result]) => {
      if (result != null && result.respuestaServicio != null) {
        if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
          this.adjuntosList = result.adjuntos;
        } else {
          this.lineaBase.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
        }
      }
    },
      error => {
        this.principalComponent.cargarErrorServicio(error);
      },
      () => {
        //Desbloquear pantalla
        this.lineaBase.blockedDocument = false;
      }
    );
  }

  verDialogoEliminarAdjunto(_adjunto) {
    this.idAdjuntoEliminar = _adjunto.id;
    this.visibleDialogoEliminarAdjunto = true;
  }

  aceptarEliminarAdjunto() {
    this.visibleDialogoEliminarAdjunto = false;
    if (this.lineaBase.esBorrador) {
      return this.informeContratistaService.srvEliminarAdjuntoInformeBorrador(this.idAdjuntoEliminar).subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.lineaBase.lanzarMensajeInfo('El adjunto se eliminó exitosamente.');
            } else {
              this.lineaBase.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            }
          }
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.cargarAdjuntosPorObligacion(this.idActividad);
          this.cargarObligaciones(this.lineaBase.idContrato, this.lineaBase.idInforme);
        }
      );
    } else {
      return this.informeContratistaService.srvEliminarAdjuntoInforme(this.idAdjuntoEliminar).subscribe(
        result => {
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.lineaBase.lanzarMensajeInfo('El adjunto se eliminó exitosamente.');
            } else {
              this.lineaBase.lanzarMensajeError(result.respuestaServicio.mensajeSalida);
            }
          }
        },
        error => {
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.cargarAdjuntosPorObligacion(this.idActividad);
          this.cargarObligaciones(this.lineaBase.idContrato, this.lineaBase.idInforme);
        }
      );
    }
  }

  cancelarEliminarAdjunto() {
    this.visibleDialogoEliminarAdjunto = false;
  }

  cancelarDialogoVerAdjunto() {
    this.visibleDialogoVerEvidencia = false;
    this.adjuntosList = [];
  }

  irVerDialogoSubirArchivo(obligacion, rowIndex) {
    this.rowIndex = rowIndex;
    this.informeObligacion = obligacion;
    this.visibleDialogoSubirArchivo = true;
    this.rutaArchivoObligacion = this.lineaBase.vigencia + '/' + this.lineaBase.idContrato + '_' + this.lineaBase.numeroContrato + '/' + this.lineaBase.numeroDocumento + '/' + this.lineaBase.numeroPago + '/' + obligacion.id + '/';
  }

  cancelarDialogoSubirArchivo() {
    this.visibleDialogoSubirArchivo = false;
    this.archivos = [];
    this.lineaBase.blockedDocument = false;
  }

}
