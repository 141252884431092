import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { MessageService } from "primeng/api";
import { EnumRespuestaServicio } from "src/app/comun/constantes/constantes-comunes";
import { CtoAdjuntoInformeBorradorDTO } from "src/app/contratacion/contratacion-dto/adjunto-informe-borrador";
import { PrincipalComponent } from "src/app/home/principal/principal.component";
import { ReportesCuentasCobroCxpService } from "../../../reporte-contratacion-cuentas-cobro.service";
import { RpteCxpBaseComponent } from "../rpte-cxp-base/rpte-cxp-base.component";
import { CtoInformeRevisionActividadDTO } from "src/app/contratacion/contratacion-dto/informe-revision-actividad";

@Component({
  selector: 'app-rpte-cxp-obl',
  templateUrl: './rpte-cxp-obl.component.html',
  styles: []
})
export class RpteCxpOblComponent implements OnInit {

  actividadesInformeRevision: CtoInformeRevisionActividadDTO[];
  adjuntosActividadesInforme: CtoAdjuntoInformeBorradorDTO[];
  visibleDialogoVerAdjuntos: boolean;

  constructor(
    private lineaBase: RpteCxpBaseComponent,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private messageService: MessageService,
    private reporteCuentaCobroCxpService: ReportesCuentasCobroCxpService,
    private principalComponent: PrincipalComponent
  ) { }

  ngOnInit() {
    // asdasdasdasdasdasdas
    this.cargarActividades(this.lineaBase.idInformeParam);
  }

  cargarActividades(idInforme: number) {
    this.lineaBase.blockedDocument = true;
    this.reporteCuentaCobroCxpService.srvConsultarActividadesInforme(idInforme)
      .subscribe(
        result => {
          // console.log('cargarActividades');
          // console.log(JSON.stringify(result));
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.actividadesInformeRevision = result.actividadesInformeRevision;
            } else {
              // console.log(result.respuestaServicio.mensajeSalida);
              this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.lineaBase.blockedDocument = false;
        }
      );
  }

  irVerDialogoVerAdjuntosAnexos(idActvidad: number) {
    this.visibleDialogoVerAdjuntos = true;
    this.ConsultarAdjuntosPorActividad(idActvidad);
  }

  ConsultarAdjuntosPorActividad(idActvidad: number) {
    this.reporteCuentaCobroCxpService.srvConsultarAdjuntosPorActividadInforme(idActvidad)
      .subscribe(
        result => {
          // console.log('cargarAdjuntosPorAnexosTributarios');
          // console.log(JSON.stringify(result));
          if (result != null && result.respuestaServicio != null) {
            if (result.respuestaServicio.codigoSalida === EnumRespuestaServicio.CODIGO_EXITO_OPERACION) {
              this.adjuntosActividadesInforme = result.adjuntos;
            } else {
              // console.log(result.respuestaServicio.mensajeSalida);
              this.messageService.add({ severity: 'error', summary: 'Error', detail: result.respuestaServicio.mensajeSalida, life: 10000 });
            }
          } else {
            console.error(EnumRespuestaServicio.ERROR_CONSUMO_SERVICIO);
          }
        },
        error => {
          console.error(error);
          this.principalComponent.cargarErrorServicio(error);
        },
        () => {
          this.lineaBase.blockedDocument = false;
        }
      );
  }

}


