import { Injectable } from '@angular/core';
import { Observable } from 'rxjs-compat/Observable';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { map, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { CtoReporteCxpContratosRptDTO } from '../contratacion-dto/cto-reporte-cxp-contratos.dto';
import { CtoReporteCxpContratoPlanPagoCuentaCobroRptDTO } from '../contratacion-dto/cto-reporte-cxp-contrato-plan-pago-cuenta-cobro.dto';
import { CtoReporteCxpDevolucionesTesoreriaRptDTO } from '../contratacion-dto/cto-reporte-cxp-devoluciones-tesoreria.dto';
import { CtoAdjuntoInformeBorradorRpt } from '../contratacion-dto/adjunto-informe-borrador';
import { CtoContratoPlanPagoVigenteRpt } from '../contratacion-dto/contrato-plan-pago-vigente';
import { CtoInformeContratistaObservacionRpt } from '../contratacion-dto/informe-contratista-observacion';
import { CtoInformeRevisionActividadesRpt } from '../contratacion-dto/informe-revision-actividad';
import { CtoInformeRevisionAnexosRpt } from '../contratacion-dto/informe-revision-anexo';
import { CtoInformeRevisionEstadoPersonaAprobacionRpt } from '../contratacion-dto/informe-revision-aprobacion';
import { CtoInformeRevisionObligacionPagoRpt } from '../contratacion-dto/informe-revision-obligacion-pago';
import { CtoReporteCuentasCobroCxpRpt } from '../contratacion-dto/reporte-cuentas-cxp';
import { CtoReporteInformeContratistaRpt } from '../contratacion-dto/reporte-informe-contratista';
import { CtoReporteCuentasCobroCxpComparativoRpt } from '../contratacion-dto/rpte-cxp-cmpvo';
import { CtoReporteCuentasCobroCxpTotalDendenciasRpt } from '../contratacion-dto/rpte-cxp-total-dpn';
import { CtoReporteCuentasCobroCxpTotalRpt } from '../contratacion-dto/rpte-cxp-total';



@Injectable({
    providedIn: 'root'
})
export class ReportesCuentasCobroCxpService {

    public END_POINT = '';
    // Http Options
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(private http: HttpClient, public datepipe: DatePipe) {
        this.END_POINT = environment.baseUrl;
    }

    /**
     * Metodo que llama al servicio para consultar el listado de cuenta de cobro de contratista, los parametros son opcionales, por defecto deben ser null
     * @param idContrato Id del Contrato 
     * @param numeroDocumentoContratista Numero de documento del contratista
     * @param pasoInforme Objeto de tipo PasoInforme
     * @param fechaInicioPaso FechaInicio del paso a consultar
     * @param fechaFinPaso FechaFin del paso a consultar
     * @param idPersonaRevisaInforme Id de la perosna que revisa el informe
     * @param filaDesde Número de la fila a consultar (Lazy)
     * @param cantidadRegistros Cantidad de registros a consultar
     * @param buscar Texto que filtre dentro del listado a consultar
     * @param campoOrden Campo que determina el orden del listado (hay unos definidos en el procedimiento de bd)
     * @param ascDesc Número que determina si la consulta es ascendente o descendente
     * @returns Resultado de la operación con el listado de Informes de Constratista de tipo CtoReporteInformeContratistaRpt
     */
    srvConsultarReporteInformesContratista(idContrato?: number, numeroDocumentoContratista?: string,
        pasoInforme?: any, fechaInicioPaso?: Date, fechaFinPaso?: Date, idPersonaRevisaInforme?: number,
        filaDesde?: number, cantidadRegistros?: number, buscar?: string, campoOrden?: string, ascDesc?: number): Observable<CtoReporteInformeContratistaRpt> {
        let urlEndpoint = '/CtoReportesCuentasCobroCxp/ConsultarReporteInformesContratista?';
        if (idContrato != null) {
            urlEndpoint = urlEndpoint + '&idContrato=' + idContrato;
        }
        if (numeroDocumentoContratista != null) {
            urlEndpoint = urlEndpoint + '&numeroDocumentoContratista=' + numeroDocumentoContratista;
        }
        if (pasoInforme != null) {
            urlEndpoint = urlEndpoint + '&idPaso=' + pasoInforme.id;
        }
        if (fechaInicioPaso != null) {
            urlEndpoint = urlEndpoint + '&fechaInicioPaso=' + this.datepipe.transform(fechaInicioPaso, 'yyyy/MM/dd');
        }
        if (fechaFinPaso != null) {
            urlEndpoint = urlEndpoint + '&fechaFinPaso=' + this.datepipe.transform(fechaFinPaso, 'yyyy/MM/dd');
        }
        if (idPersonaRevisaInforme != null) {
            urlEndpoint = urlEndpoint + '&idPersonaRevisaInforme=' + idPersonaRevisaInforme;
        }
        if (buscar != null) {
            urlEndpoint = urlEndpoint + '&buscar=' + buscar;
        }
        if (filaDesde != null) {
            urlEndpoint = urlEndpoint + '&filaDesde=' + filaDesde;
        }
        if (cantidadRegistros != null) {
            urlEndpoint = urlEndpoint + '&cantidadRegistros=' + cantidadRegistros;
        }
        if (campoOrden != null) {
            urlEndpoint = urlEndpoint + '&campoOrden=' + campoOrden;
        }
        if (ascDesc != null) {
            if (ascDesc == 1) {
                urlEndpoint = urlEndpoint + '&ascDesc=asc';
            } else {
                urlEndpoint = urlEndpoint + '&ascDesc=desc';
            }
        }
        return this.http.get<CtoReporteInformeContratistaRpt>(this.END_POINT + urlEndpoint)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    /**
     * Metodo que llama al servicio para consultar el listado de pagos por contrato 
     * @param idContrato Id del contrato
     * @returns Resultado de la operación con el listado de Informes de Constratista de tipo CtoContratoPlanPagoVigenteRpt
     */
    srvConsultarReporteContratoPlanPagoVigente(idContrato: number): Observable<CtoContratoPlanPagoVigenteRpt> {
        return this.http.get<CtoContratoPlanPagoVigenteRpt>(this.END_POINT + '/CtoReportesCuentasCobroCxp/ConsultarContratoPlanPagoVigente/' + idContrato)
            .pipe(
                map(data => {
                    return data;
                }),
            );
    }

    /**
     * Metodo que llama al servicio para consultar el listado de cuentas de cobro tramitadas y que al menos una vez han pasado por Central de Pagos, los parametros son opcionales, por defecto deben ser null
     * @param idContrato  Id del Contrato 
     * @param idInforme  Id del Informe contratista
     * @param numeroDocumentoContratista Numero de documento del contratista
     * @param pasoInforme Objeto de tipo PasoInforme
     * @param fechaInicioPaso FechaInicio del paso a consultar
     * @param fechaFinPaso FechaFin del paso a consultar
     * @param filaDesde Número de la fila a consultar (Lazy)
     * @param cantidadRegistros Cantidad de registros a consultar
     * @param buscar Texto que filtre dentro del listado a consultar
     * @param campoOrden Campo que determina el orden del listado (hay unos definidos en el procedimiento de bd)
     * @param ascDesc Número que determina si la consulta es ascendente o descendente
     * @returns Resultado de la operación con el listado de Cuentas de cobro de tipo CtoReporteCuentasCobroCxpRpt
     */
    srvConsultarReporteCuentasCobroCxp(idContrato?: number, idInforme?: number, numeroDocumentoContratista?: string,
        pasoInforme?: any, fechaInicioPaso?: Date, fechaFinPaso?: Date,
        filaDesde?: number, cantidadRegistros?: number, buscar?: string, campoOrden?: string, ascDesc?: number): Observable<CtoReporteCuentasCobroCxpRpt> {
        let urlEndpoint = '/CtoReportesCuentasCobroCxp/ConsultarReporteCuentasCobroCxp?';
        if (idContrato != null) {
            urlEndpoint = urlEndpoint + '&idContrato=' + idContrato;
        }
        if (idInforme != null) {
            urlEndpoint = urlEndpoint + '&idInforme=' + idInforme;
        }
        if (numeroDocumentoContratista != null) {
            urlEndpoint = urlEndpoint + '&numeroDocumentoContratista=' + numeroDocumentoContratista;
        }
        if (pasoInforme != null) {
            urlEndpoint = urlEndpoint + '&idPaso=' + pasoInforme.id;
        }
        if (fechaInicioPaso != null) {
            urlEndpoint = urlEndpoint + '&fechaInicioPaso=' + this.datepipe.transform(fechaInicioPaso, 'yyyy/MM/dd');
        }
        if (fechaFinPaso != null) {
            urlEndpoint = urlEndpoint + '&fechaFinPaso=' + this.datepipe.transform(fechaFinPaso, 'yyyy/MM/dd');
        }
        if (buscar != null) {
            urlEndpoint = urlEndpoint + '&buscar=' + buscar;
        }
        if (filaDesde != null) {
            urlEndpoint = urlEndpoint + '&filaDesde=' + filaDesde;
        }
        if (cantidadRegistros != null) {
            urlEndpoint = urlEndpoint + '&cantidadRegistros=' + cantidadRegistros;
        }
        if (campoOrden != null) {
            urlEndpoint = urlEndpoint + '&campoOrden=' + campoOrden;
        }
        if (ascDesc != null) {
            if (ascDesc == 1) {
                urlEndpoint = urlEndpoint + '&ascDesc=asc';
            } else {
                urlEndpoint = urlEndpoint + '&ascDesc=desc';
            }
        }
        return this.http.get<CtoReporteCuentasCobroCxpRpt>(this.END_POINT + urlEndpoint)
            .pipe(
                map(data => {
                    return data;
                }),
            );
    }

    /**
     * Metodo que llama al servicio para consultar el informe de una cuenta de cobro por Id 
     * @param idInforme Id del Informe
     * @returns Resultado de la operación con el registro de Cuenta de cobro de tipo CtoReporteCuentasCobroCxpRpt
     */
    srvConsultarReporteCuentasCobroCxpPorIdInforme(idInforme?: number): Observable<CtoReporteCuentasCobroCxpRpt> {
        let urlEndpoint = '/CtoReportesCuentasCobroCxp/ConsultarReporteCuentasCobroCxpPorIdInforme?';
        if (idInforme != null) {
            urlEndpoint = urlEndpoint + '&idInforme=' + idInforme;
        }
        return this.http.get<CtoReporteCuentasCobroCxpRpt>(this.END_POINT + urlEndpoint)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    /**
     * Metodo que llama al servicio para consultar las actividades relacionadas a las obligaciones contractuales de una cuenta de cobro por Id de Informe
     * @param idInforme Id del informe contratista
     * @returns Resultado de la operación con el listado de actividades asociadas al informe de tipo CtoInformeRevisionActividadesRpt
     */
    srvConsultarActividadesInforme(idInforme: number): Observable<CtoInformeRevisionActividadesRpt> {
        return this.http.get<CtoInformeRevisionActividadesRpt>(this.END_POINT + '/CtoReportesCuentasCobroCxp/ConsultarReporteCuentasCxpActividadesInforme/' + idInforme)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    /**
     * Metodo que llama al servicio para consultar los adjuntos de las actividades asociadas a las obligaciones de una cuenta de cobro por Id actividad
     * @param idActividad Id de la actividad
     * @returns Resultado de la operación con el listado de actividades asociadas al informe de tipo ConsultarAdjuntosRpt
     */
    srvConsultarAdjuntosPorActividadInforme(idActividad: number): Observable<CtoAdjuntoInformeBorradorRpt> {
        return this.http.get<CtoAdjuntoInformeBorradorRpt>(this.END_POINT + '/CtoReportesCuentasCobroCxp/ConsultarAdjuntosPorActividadInforme/' + idActividad)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    /**
     * Metodo que llama al servicio para consultar las planillas parafiscales de de una cuenta de cobro por Id Informe
     * @param idInforme Id del informe contratista
     * @returns Resultado de la operación con el listado de planillas asociadas al informe de tipo ConsultarAdjuntosRpt
     */
    srvConsultarPlanillasInforme(idInforme: number): Observable<CtoAdjuntoInformeBorradorRpt> {
        return this.http.get<CtoAdjuntoInformeBorradorRpt>(this.END_POINT + '/CtoReportesCuentasCobroCxp/ConsultarPlanillasInforme/' + idInforme)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    /**
     * Metodo que llama al servicio para consultar las anexos de un de una cuenta de cobro por Id Informe y subtipo y tipo Anexo 
     * @param idInforme Id del informe contratista
     * @param subtipoAnexo Subtipo del anexo
     * @param idsTipoAnexo Id del tipo de anexo
     * @returns Resultado de la operación con el listado de anexos asociadas al informe de tipo CtoInformeRevisionAnexosRpt
     */
    srvConsultarAnexosInforme(idInforme: number, subtipoAnexo: string, idsTipoAnexo: string): Observable<CtoInformeRevisionAnexosRpt> {
        let urlEndpoint = '/CtoReportesCuentasCobroCxp/ConsultarAnexosInforme?';
        if (idInforme != null) {
            urlEndpoint = urlEndpoint + '&idInforme=' + idInforme;
        }
        if (subtipoAnexo != null) {
            urlEndpoint = urlEndpoint + '&subtipoAnexo=' + subtipoAnexo;
        }
        if (idsTipoAnexo != null) {
            urlEndpoint = urlEndpoint + '&idsTipoAnexo=' + idsTipoAnexo;
        }

        return this.http.get<CtoInformeRevisionAnexosRpt>(this.END_POINT + urlEndpoint)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    /**
     * Metodo que llama al servicio para consultar las adjuntos de un anexo
     * @param id Id del Anexo
     * @returns Resultado de la operación con el listado de adjuntos de un anexo asociadas al informe de tipo ConsultarAdjuntosRpt
     */
    srvConsultarAdjuntosPorAnexoInforme(id: number): Observable<CtoAdjuntoInformeBorradorRpt> {
        return this.http.get<CtoAdjuntoInformeBorradorRpt>(this.END_POINT + '/CtoReportesCuentasCobroCxp/ConsultarAdjuntosPorAnexoInforme/' + id)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    /**
     * Metodo que llama al servicio para consultar el registro presupuestal de obligacion de pago para un informe
     * @param idInforme Id del informe contratista
     * @returns Resultado de la operación con la información del registro presupuestal de obligacion de pago de tipo CtoInformeRevisionObligacionPagoRpt
     */
    srvConsultarObligacionPago(idInforme: number): Observable<CtoInformeRevisionObligacionPagoRpt> {
        return this.http.get<CtoInformeRevisionObligacionPagoRpt>(this.END_POINT + '/CtoReportesCuentasCobroCxp/ConsultarObligacionPago/' + idInforme)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    /**
     * Metodo que llama al servicio para consultar las observaciones de un informe realizadas por los revisores
     * @param idInforme Id del informe contratista
     * @returns Resultado de la operación con el listado de observaciones de tipo CtoInformeContratistaObservacionRpt
     */
    srvConsultarObservacionesInforme(idInforme: number): Observable<CtoInformeContratistaObservacionRpt> {
        return this.http.get<CtoInformeContratistaObservacionRpt>(this.END_POINT + '/CtoReportesCuentasCobroCxp/ConsultarObservacionesInforme/' + idInforme)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    /**
     * Metodo que llama al servicio para consultar el estado de revión por cada revisor en cada paso del informe
     * @param idInforme Id del informe contratista
     * @returns Resultado de la operación con el listado de estados por persona revisor de tipo CtoInformeRevisionEstadoPersonaAprobacionRpt
     */
    srvConsultarPersonaEstadoInforme(idInforme: number): Observable<CtoInformeRevisionEstadoPersonaAprobacionRpt> {
        return this.http.get<CtoInformeRevisionEstadoPersonaAprobacionRpt>(this.END_POINT + '/CtoReportesCuentasCobroCxp/ConsultarPersonaEstadoInforme/' + idInforme)
            .pipe(
                map(data => {
                    return data;
                }),
                retry(1));
    }

    /**
     * Metodo que llama al servicio para consultar el consolidado de cuentas de cobro por dependencia, los pareamtros 
     * @param fechaInicioPaso FechaInicio del paso a consultar
     * @param fechaFinPaso FechaFin del paso a consultar
     * @returns Resultado de la operación con el listado de dependencias y el consolidado de las cuentas de tipo CtoReporteCuentasCobroCxpTotalDendenciasRpt
     */
    srvConsultarReporteCuentasCobroCxpConsolidadoDependencias(
        fechaInicioPaso?: Date, fechaFinPaso?: Date): Observable<CtoReporteCuentasCobroCxpTotalDendenciasRpt> {
        let urlEndpoint = '/CtoReportesCuentasCobroCxp/ConsultarReporteCuentasCobroCxpConsolidadoDependencias?';

        if (fechaInicioPaso != null) {
            urlEndpoint = urlEndpoint + '&fechaInicioPaso=' + this.datepipe.transform(fechaInicioPaso, 'yyyy/MM/dd');
        }
        if (fechaFinPaso != null) {
            urlEndpoint = urlEndpoint + '&fechaFinPaso=' + this.datepipe.transform(fechaFinPaso, 'yyyy/MM/dd');
        }
        return this.http.get<CtoReporteCuentasCobroCxpTotalDendenciasRpt>(this.END_POINT + urlEndpoint)
            .pipe(
                map(data => {
                    return data;
                }),);
    }

    /**
     * Metodo que llama al servicio para consultar el consolidado de cuentas de cobro (Cuentas rechazadas,Cuentas en revisión,Cuentas aprobadas,Cuentas recibidas)
     * @param fechaInicioPaso FechaInicio del paso a consultar
     * @param fechaFinPaso FechaFin del paso a consultar
     * @returns Resultado de la operación con el listado de estado de cuenta con su cantidad de tipo CtoReporteCuentasCobroCxpTotalRpt
     */
    srvConsultarReporteCuentasCobroCxpConsolidado(
        fechaInicioPaso?: Date, fechaFinPaso?: Date): Observable<CtoReporteCuentasCobroCxpTotalRpt> {
        let urlEndpoint = '/CtoReportesCuentasCobroCxp/ConsultarReporteCuentasCobroCxpConsolidado?';

        if (fechaInicioPaso != null) {
            urlEndpoint = urlEndpoint + '&fechaInicioPaso=' + this.datepipe.transform(fechaInicioPaso, 'yyyy/MM/dd');
        }
        if (fechaFinPaso != null) {
            urlEndpoint = urlEndpoint + '&fechaFinPaso=' + this.datepipe.transform(fechaFinPaso, 'yyyy/MM/dd');
        }
        return this.http.get<CtoReporteCuentasCobroCxpTotalRpt>(this.END_POINT + urlEndpoint)
            .pipe(
                map(data => {
                    return data;
                }),
            );
    }

    /**
     * Metodo que llama al servicio para consultar el comparativo de cuentas de cobro, por cada cuenta de cobro se evidencia en que fecha cambio de paso y/o rechazó de parte de central de pagos
     * @param fechaInicioPaso FechaInicio del paso a consultar
     * @param fechaFinPaso FechaFin del paso a consultar
     * @returns Resultado de la operación con el listado de cuentas de cobro de tipo CtoReporteCuentasCobroCxpComparativoRpt
     */
    srvConsultarReporteCuentasCobroCxpComparativo(
        fechaInicioPaso?: Date, fechaFinPaso?: Date): Observable<CtoReporteCuentasCobroCxpComparativoRpt> {
        let urlEndpoint = '/CtoReportesCuentasCobroCxp/ConsultarReporteCuentasCobroCxpComparativo?';

        if (fechaInicioPaso != null) {
            urlEndpoint = urlEndpoint + '&fechaInicioPaso=' + this.datepipe.transform(fechaInicioPaso, 'yyyy/MM/dd');
        }
        if (fechaFinPaso != null) {
            urlEndpoint = urlEndpoint + '&fechaFinPaso=' + this.datepipe.transform(fechaFinPaso, 'yyyy/MM/dd');
        }
        return this.http.get<CtoReporteCuentasCobroCxpComparativoRpt>(this.END_POINT + urlEndpoint)
            .pipe(
                map(data => {
                    return data;
                }),
            );
    }

    /**
     * Metodo que llama al servicio para consultar el listado de cuenta de cobro devueltas desde Tesorería a Central de Pagos
     * @param fechaInicioPaso Rango de Fecha Inicio. Fecha del Paso 5	Revisión central pagos - devolución
     * @param fechaFinPaso Rango de Fecha Fin. Fecha del Paso 5	Revisión central pagos - devolución
     * @param filaDesde Valor Opcional >0 para consultar desde una fila en particular
     * @param cantidadRegistros Valor Opcional >0 para consultar X cantidad de registros
     * @param buscar Valor Opcional para consultar X dato dentro de la informacíon, se tiene definido en BD
     * @param campoOrden Valor Opcional para ordenar la información, se tiene definido en BD
     * @param ascDesc Valor Opcional númerico para ordenar los valores del listado a consultar de forma ascendente o descente
     * @returns Resultado de la operación con el listado de cuentas de cobro de devoluciones de tipo CtoReporteCxpDevolucionesTesoreriaRptDTO
     */
    srvConsultarReporteCuentasCobroCxpDevolucionesTesoreria(
        fechaInicioPaso?: Date, fechaFinPaso?: Date, filaDesde?: number, cantidadRegistros?: number, buscar?: string, campoOrden?: string, ascDesc?: number): Observable<CtoReporteCxpDevolucionesTesoreriaRptDTO> {
        let urlEndpoint = '/CtoReportesCuentasCobroCxp/ConsultarReporteCuentasCobroCxpDevolucionesTesoreria?';

        if (fechaInicioPaso != null) {
            urlEndpoint = urlEndpoint + '&fechaInicioPaso=' + this.datepipe.transform(fechaInicioPaso, 'yyyy/MM/dd HH:mm');
        }
        if (fechaFinPaso != null) {
            urlEndpoint = urlEndpoint + '&fechaFinPaso=' + this.datepipe.transform(fechaFinPaso, 'yyyy/MM/dd HH:mm');
        }
        if (buscar != null) {
            urlEndpoint = urlEndpoint + '&buscar=' + buscar;
        }
        if (filaDesde != null) {
            urlEndpoint = urlEndpoint + '&filaDesde=' + filaDesde;
        }
        if (cantidadRegistros != null) {
            urlEndpoint = urlEndpoint + '&cantidadRegistros=' + cantidadRegistros;
        }
        if (campoOrden != null) {
            urlEndpoint = urlEndpoint + '&campoOrden=' + campoOrden;
        }
        if (ascDesc != null) {
            if (ascDesc == 1) {
                urlEndpoint = urlEndpoint + '&ascDesc=asc';
            } else {
                urlEndpoint = urlEndpoint + '&ascDesc=desc';
            }
        }
        return this.http.get<CtoReporteCxpDevolucionesTesoreriaRptDTO>(this.END_POINT + urlEndpoint)
            .pipe(
                map(data => {
                    return data;
                }),
            );
    }

    /**
     * Metodo que llama al servicio para consultar el listado de planes de pago de los contratos vs su cuenta de cobro, los parametros son opcionales
     * @param vigencia Vigencia del contrato
     * @param idPaso Id Paso Informe Contratista
     * @param fechaDesdePlanPago Rango de Fecha Inicio. Fecha Fin del Plan de Pago
     * @param fechaHastaPlanPago Rango de Fecha Fin. Fecha Fin del Plan de Pago
     * @param filaDesde Valor Opcional >0 para consultar desde una fila en particular
     * @param cantidadRegistros Valor Opcional >0 para consultar X cantidad de registros
     * @param buscar Valor Opcional para consultar X dato dentro de la informacíon, se tiene definido en BD
     * @param campoOrden Valor Opcional para ordenar la información, se tiene definido en BD
     * @param ascDesc Valor Opcional númerico para ordenar los valores del listado a consultar de forma ascendente o descente
     * @returns Resultado de la operación con el listado de cuentas de cobro de tipo CtoReporteCuentasCobroCxpComparativoRpt
     */
    srvGetConsultarReporteCxpPlanesPagoCuentasCobro(vigencia?: number, idPaso?: number, fechaDesdePlanPago?: Date, fechaHastaPlanPago?: Date, filaDesde?: number, cantidadRegistros?: number, buscar?: string, campoOrden?: string, ascDesc?: number): Observable<CtoReporteCxpContratoPlanPagoCuentaCobroRptDTO> {
        let urlEndpoint = '/CtoReportesCuentasCobroCxp/GetConsultarReporteCxpPlanesPagoCuentasCobro?';
        if (vigencia != null) {
            urlEndpoint = urlEndpoint + '&vigencia=' + vigencia;
        }
        if (idPaso != null) {
            urlEndpoint = urlEndpoint + '&idPaso=' + idPaso;
        }
        if (fechaDesdePlanPago != null) {
            urlEndpoint = urlEndpoint + '&fechaDesdePlanPago=' + this.datepipe.transform(fechaDesdePlanPago, 'yyyy/MM/dd');
        }
        if (fechaHastaPlanPago != null) {
            urlEndpoint = urlEndpoint + '&fechaHastaPlanPago=' + this.datepipe.transform(fechaHastaPlanPago, 'yyyy/MM/dd');
        }
        if (buscar != null) {
            urlEndpoint = urlEndpoint + '&buscar=' + buscar;
        }
        if (filaDesde != null) {
            urlEndpoint = urlEndpoint + '&filaDesde=' + filaDesde;
        }
        if (cantidadRegistros != null) {
            urlEndpoint = urlEndpoint + '&cantidadRegistros=' + cantidadRegistros;
        }
        if (campoOrden != null) {
            urlEndpoint = urlEndpoint + '&campoOrden=' + campoOrden;
        }
        if (ascDesc != null) {
            if (ascDesc == 1) {
                urlEndpoint = urlEndpoint + '&ascDesc=asc';
            } else {
                urlEndpoint = urlEndpoint + '&ascDesc=desc';
            }
        }
        return this.http.get<CtoReporteCxpContratoPlanPagoCuentaCobroRptDTO>(this.END_POINT + urlEndpoint)
            .pipe(
                map(data => {
                    return data;
                }),
            );
    }

    /**
     * Metodo que llama al servicio para consultar el reporte de contratos para central de pagos
     * @param vigencia  Vigencia del contrato
     * @param fechaDesdeActaInicio Rango de Fecha Inicio. Fecha Acta Inicio del contrato
     * @param fechaHastaActainicio Rango de Fecha Fin. Fecha Acta Inicio del contrato
     * @param fechaDesdeSuscripcion Rango de Fecha Inicio. Fecha Suscripción del contrato
     * @param fechaHastaSuscripcion Rango de Fecha Fin. Fecha Suscripción del contrato
     * @param fechaDesdeTerminacionContrato Rango de Fecha Inicio. Fecha Terminación del contrato
     * @param fechaHastaTerminacionContrato Rango de Fecha Fin. Fecha Terminación del contrato
     * @param filaDesde Valor Opcional >0 para consultar desde una fila en particular
     * @param cantidadRegistros Valor Opcional >0 para consultar X cantidad de registros
     * @param buscar Valor Opcional para consultar X dato dentro de la informacíon, se tiene definido en BD
     * @param campoOrden Valor Opcional para ordenar la información, se tiene definido en BD
     * @param ascDesc Valor Opcional númerico para ordenar los valores del listado a consultar de forma ascendente o descente
     * @returns Resultado de la operación con el listado de contratos de tipo CtoReporteCxpContratosRptDTO
     */
    srvConsultarReporteCxpContratos(vigencia?: number,
        fechaDesdeActaInicio?: Date, fechaHastaActainicio?: Date,
        fechaDesdeSuscripcion?: Date, fechaHastaSuscripcion?: Date,
        fechaDesdeTerminacionContrato?: Date, fechaHastaTerminacionContrato?: Date,
        filaDesde?: number, cantidadRegistros?: number, buscar?: string, campoOrden?: string, ascDesc?: number): Observable<CtoReporteCxpContratosRptDTO> {
        let urlEndpoint = '/CtoReportesCuentasCobroCxp/ConsultarReporteCxpContratos?';

        if (vigencia != null) {
            urlEndpoint = urlEndpoint + '&vigencia=' + vigencia;
        }

        if (fechaDesdeActaInicio != null) {
            urlEndpoint = urlEndpoint + '&fechaDesdeActaInicio=' + this.datepipe.transform(fechaDesdeActaInicio, 'yyyy/MM/dd');
        }
        if (fechaHastaActainicio != null) {
            urlEndpoint = urlEndpoint + '&fechaHastaActainicio=' + this.datepipe.transform(fechaHastaActainicio, 'yyyy/MM/dd');
        }
        if (fechaDesdeSuscripcion != null) {
            urlEndpoint = urlEndpoint + '&fechaDesdeSuscripcion=' + this.datepipe.transform(fechaDesdeSuscripcion, 'yyyy/MM/dd');
        }
        if (fechaHastaSuscripcion != null) {
            urlEndpoint = urlEndpoint + '&fechaHastaSuscripcion=' + this.datepipe.transform(fechaHastaSuscripcion, 'yyyy/MM/dd');
        }
        if (fechaDesdeTerminacionContrato != null) {
            urlEndpoint = urlEndpoint + '&fechaDesdeTerminacionContrato=' + this.datepipe.transform(fechaDesdeTerminacionContrato, 'yyyy/MM/dd');
        }
        if (fechaHastaTerminacionContrato != null) {
            urlEndpoint = urlEndpoint + '&fechaHastaTerminacionContrato=' + this.datepipe.transform(fechaHastaTerminacionContrato, 'yyyy/MM/dd');
        }
        if (buscar != null) {
            urlEndpoint = urlEndpoint + '&buscar=' + buscar;
        }
        if (filaDesde != null) {
            urlEndpoint = urlEndpoint + '&filaDesde=' + filaDesde;
        }
        if (cantidadRegistros != null) {
            urlEndpoint = urlEndpoint + '&cantidadRegistros=' + cantidadRegistros;
        }
        if (campoOrden != null) {
            urlEndpoint = urlEndpoint + '&campoOrden=' + campoOrden;
        }
        if (ascDesc != null) {
            if (ascDesc == 1) {
                urlEndpoint = urlEndpoint + '&ascDesc=asc';
            } else {
                urlEndpoint = urlEndpoint + '&ascDesc=desc';
            }
        }

        return this.http.get<CtoReporteCxpContratosRptDTO>(this.END_POINT + urlEndpoint)
            .pipe(
                map(data => {
                    return data;
                }),
            );
    }

}
